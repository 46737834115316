import React, { useCallback, useState, useEffect } from 'react';

import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Radio,
  RadioGroup
} from '@chakra-ui/react';

const RadioComponent = ({
  fieldId,
  submitAttempted,
  fieldLabel,
  fieldValue: options,
  required,
  errorMessage,
  onReady = () => {},
  onChange = (value) => {}
}) => {
  const [isError, setIsError] = useState(required);

  const handleOnChange = useCallback(
    (value) => {
      const isInvalid = required === true && value.length === 0;

      setIsError(isInvalid);

      onChange({
        fieldId,
        value,
        label: fieldLabel,
        isValid: isInvalid === false
      });
    },
    [onChange, fieldId, fieldLabel, required]
  );

  useEffect(() => {
    onReady({
      fieldId,
      required
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControl
      mb="3"
      isInvalid={submitAttempted && isError}
      isRequired={required}>
      <FormLabel id={`field-label-radio-${fieldId}-${fieldLabel}`} mb="2">
        {fieldLabel}
      </FormLabel>
      <RadioGroup
        display="flex"
        gap="4"
        mb="4"
        name={fieldLabel}
        onChange={(event) => handleOnChange(event)}
        onBlur={(event) => handleOnChange(event)}>
        {options &&
          options.map(({ value, index }) => {
            return (
              <Flex
                alignContent="center"
                gap="2"
                key={`${fieldId}-${value}-${index}-radio`}>
                <Radio
                  id={`${fieldId}-${value}-${index}`}
                  background="white"
                  borderRadius="xs"
                  borderColor="primary.dark-blue"
                  value={value}
                />
                <Box>{value}</Box>
              </Flex>
            );
          })}
      </RadioGroup>
      {submitAttempted && isError && (
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export default RadioComponent;
