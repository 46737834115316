import { environmentType, isLocalBuild } from '@helpers/environment';

/**
 * A helper function that pushes an event to the data layer.
 * @param {string} event The event name.
 * @param {object} interaction The interaction data, eg. click_text, link_url, etc.
 */
export default function dataLayerPush({ event, interaction = {} }) {
  // Do nothing if no event has been passed.
  if (typeof event !== `string`) {
    return;
  }

  const dataLayerObject = {
    event,
    interaction: {
      path: window.location.pathname,
      ...interaction
    }
  };

  // Push to event on all environments except local.
  if (isLocalBuild === false) {
    window.dataLayer.push(dataLayerObject);
  }

  // Log the event for debugging.
  if (environmentType !== `master` && environmentType !== `production`) {
    /* eslint-disable no-console */
    console.log(`Data layer push:`, dataLayerObject);
  }
}
