import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

const applyTitleTemplate = (title = ``, template = `%s`) => {
  return template.replace(`%s`, title);
};

const Seo = ({
  seo,
  pathname = `/`,
  siteLanguage = `en`,
  defaultTitle,
  children
}) => {
  const {
    site: {
      siteMetadata: { title: siteTitle, siteUrl }
    },
    strapiSeo: { defaultMetaImage }
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          siteUrl
        }
      }
      strapiSeo {
        defaultMetaImage {
          localFile {
            childImageSharp {
              gatsbyImageData(formats: JPG)
            }
          }
        }
      }
    }
  `);

  // Must use origin because some markets have a language path in the site url eg. UK
  const { origin } = new URL(siteUrl);

  let title =
    typeof defaultTitle === `string`
      ? applyTitleTemplate(defaultTitle, `%s | ${siteTitle}`)
      : siteTitle;
  let description = null;
  let image =
    typeof defaultMetaImage === `object` && defaultMetaImage !== null
      ? getImage(defaultMetaImage.localFile)
      : null;
  let canonical = `${origin}${pathname}`;
  let keywords = null;
  let noIndex = null;

  if (typeof seo === `object` && seo !== null) {
    if (seo.metaTitle) {
      title = applyTitleTemplate(seo.metaTitle, `%s | ${siteTitle}`);
    }
    if (seo.metaDescription) {
      description = seo.metaDescription;
    }
    if (seo.metaImage) {
      image = getImage(seo.metaImage.localFile) || image;
    }
    if (seo.canonicalURL) {
      canonical = seo.canonicalURL;
    }
    ({ keywords, noIndex } = seo);
  }

  return (
    <>
      <html lang={siteLanguage} />
      <title>{title}</title>
      {typeof noIndex === `boolean` && noIndex === true && (
        <meta name="robots" content="noindex, nofollow" />
      )}
      {typeof keywords === `string` && (
        <meta name="keywords" content={keywords} />
      )}
      {typeof description === `string` && (
        <meta name="description" content={description} />
      )}
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`${origin}${pathname}`} />
      {typeof description === `string` && (
        <meta property="og:description" content={description} />
      )}
      {typeof image === `object` && image !== null && (
        <meta
          property="og:image"
          content={`${origin}${image.images.fallback.src}`}
        />
      )}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:url" content={`${origin}${pathname}`} />
      {typeof description === `string` && (
        <meta name="twitter:description" content={description} />
      )}
      {typeof image === `string` && (
        <meta name="twitter:image" content={image} />
      )}
      <link rel="canonical" href={canonical} />
      {children}
    </>
  );
};

export default Seo;
