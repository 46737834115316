export const postForm = async (action, data = {}, asString = true) => {
  const settings = {
    method: `POST`,
    body: JSON.stringify({ data }),
    // eslint-disable-next-line no-extra-parens
    ...(!asString && { headers: { 'Content-Type': `application/json` } })
  };
  try {
    const fetchResponse = await fetch(action, settings);

    return await fetchResponse.json();
  } catch (err) {
    return err;
  }
};

export const mapFieldGroups = (fieldGroups) =>
  fieldGroups.map(({ label, fields }, groupIndex) => {
    const mappedFields = fields.map((field, fieldIndex) => {
      return {
        ...field,
        componentId: `field-${fieldIndex}`,
        fieldType:
          typeof field.fieldType === `string`
            ? JSON.parse(field.fieldType)
            : field.fieldType
      };
    });

    return {
      componentId: `field-group-${groupIndex}`,
      label,
      fields: mappedFields
    };
  });

export const blobToBase64 = (blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};
