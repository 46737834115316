import React, { useCallback, useEffect, useState } from 'react';

import {
  Textarea,
  FormControl,
  FormLabel,
  FormErrorMessage
} from '@chakra-ui/react';

const TextareaComponent = ({
  fieldId,
  submitAttempted,
  defaultPlaceholder,
  fieldLabel,
  fieldValue,
  required,
  errorMessage,
  onChange = (v) => {},
  onReady = () => {}
}) => {
  const [isError, setIsError] = useState(required);

  const handleOnChange = useCallback(
    (value) => {
      const isInvalid = required === true && value.length === 0;

      setIsError(isInvalid);

      onChange({
        fieldId,
        value,
        label: fieldLabel,
        isValid: isInvalid === false
      });
    },
    [onChange, fieldId, fieldLabel, required]
  );

  useEffect(() => {
    onReady({
      fieldId,
      required
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControl
      mb="3"
      isInvalid={submitAttempted && isError}
      isRequired={required}>
      <FormLabel
        id={`field-label-textarea-${fieldId}-${fieldLabel}`}
        htmlFor={fieldId}
        mb="2">
        {fieldLabel}
      </FormLabel>
      <Textarea
        id={fieldId}
        name={fieldLabel}
        placeholder={fieldValue || defaultPlaceholder}
        background="white"
        borderRadius="xs"
        borderColor="primary.dark-blue"
        onChange={(event) => handleOnChange(event.target.value)}
        onBlur={(event) => handleOnChange(event.target.value)}
      />
      {submitAttempted && isError && (
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export default TextareaComponent;
