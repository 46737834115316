import React, { useCallback, useState, useEffect } from 'react';

import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Checkbox,
  Box,
  Flex
} from '@chakra-ui/react';

const CheckboxComponent = ({
  fieldId,
  submitAttempted,
  defaultPlaceholder,
  fieldLabel,
  fieldValue,
  required,
  errorMessage,
  onChange = (value) => {},
  onReady = () => {}
}) => {
  const [isError, setIsError] = useState(required);

  const handleOnChange = useCallback(
    (value) => {
      const isInvalid = required === true && value !== true;
      setIsError(isInvalid);

      onChange({
        fieldId,
        value,
        label: fieldLabel,
        isValid: isInvalid === false
      });
    },
    [onChange, fieldId, fieldLabel, required]
  );

  useEffect(() => {
    onReady({
      fieldId,
      required
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControl
      mb="3"
      isInvalid={submitAttempted && isError}
      isRequired={required}>
      <FormLabel
        id={`field-label-text-${fieldId}-${fieldLabel}`}
        htmlFor={fieldId}
        mb="2">
        {fieldLabel}
      </FormLabel>
      <Flex alignItems="flex-start" gap="2">
        <Checkbox
          id={fieldId}
          name={fieldLabel}
          value={fieldValue}
          borderRadius="xs"
          background="white"
          borderColor="primary.dark-blue"
          mt="0.3125rem"
          onChange={(event) => handleOnChange(event.target.checked)}
          onBlur={(event) => handleOnChange(event.target.checked)}
        />
        <Box
          as="span"
          // Need to use dangerouslySetInnerHTML to ensure correct formatting.
          dangerouslySetInnerHTML={{ __html: fieldValue }}
          sx={{
            a: {
              textDecoration: `underline`
            }
          }}
        />
      </Flex>
      {submitAttempted && isError && (
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export default CheckboxComponent;
