import React from 'react';
import { Icon } from '@chakra-ui/react';

const CalendarIcon = (props) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.25 9.25H19.75V17C19.75 18.5188 18.5188 19.75 17 19.75H7C5.48122 19.75 4.25 18.5188 4.25 17V9.25ZM5.75 10.75V17C5.75 17.6904 6.30964 18.25 7 18.25H17C17.6904 18.25 18.25 17.6904 18.25 17V10.75H5.75Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.25 8C4.25 6.48122 5.48122 5.25 7 5.25H17C18.5188 5.25 19.75 6.48122 19.75 8V10.75H4.25V8ZM7 6.75C6.30964 6.75 5.75 7.30964 5.75 8V9.25H18.25V8C18.25 7.30964 17.6904 6.75 17 6.75H7Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 3.25C16.4142 3.25 16.75 3.58579 16.75 4V7C16.75 7.41421 16.4142 7.75 16 7.75C15.5858 7.75 15.25 7.41421 15.25 7V4C15.25 3.58579 15.5858 3.25 16 3.25Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 3.25C8.41421 3.25 8.75 3.58579 8.75 4V7C8.75 7.41421 8.41421 7.75 8 7.75C7.58579 7.75 7.25 7.41421 7.25 7V4C7.25 3.58579 7.58579 3.25 8 3.25Z"
    />
  </Icon>
);

export default CalendarIcon;
