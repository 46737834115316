import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import {
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Hide,
  Show,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  useDisclosure,
  Box,
  Image,
  AspectRatio
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { locale, isLocalBuild } from '@helpers/environment';
import LocaleList from '@components/molecules/LocaleList';

const LocaleOptions = ({ light = false }) => {
  const { allLocale, siteSettings } = useStaticQuery(graphql`
    {
      allLocale {
        nodes {
          code
          name
        }
      }
      siteSettings: strapiSiteSetting {
        changeLanguageText
      }
    }
  `);

  const flags = useMemo(() => {
    return isLocalBuild
      ? null
      : require.context(`../../assets/images/flags`, true, /\.svg$/);
  }, []);

  const { isOpen, onToggle, onClose } = useDisclosure();

  const flagImageValid = useMemo(() => {
    const currentFlag =
      flags !== null ? flags(`./${locale}.svg`).default : null;

    return (
      flags !== null &&
      typeof currentFlag !== `undefined` &&
      currentFlag !== null
    );
  }, [flags]);

  return (
    <Flex
      w="100%"
      justifyContent="space-between"
      color={light ? `#fff` : `primary.dark-blue`}>
      <Show below="lg">
        <Accordion allowMultiple width="100%">
          <AccordionItem border="none" width="100%">
            <AccordionButton justifyContent="flex-end" px={0}>
              {flagImageValid === true && (
                <AspectRatio
                  boxSize="1.5rem"
                  ratio="1"
                  display="inline-block"
                  verticalAlign="middle"
                  borderRadius="full"
                  border="1px solid white"
                  mr={2}
                  mt="-0.0625rem"
                  overflow="hidden">
                  <Image src={flags(`./${locale}.svg`).default} alt={locale} />
                </AspectRatio>
              )}
              <Box
                as="span"
                fontSize="xs"
                color="white"
                textTransform="uppercase"
                mr="4">
                {locale}
              </Box>
              <Box
                as="span"
                fontWeight="800"
                fontSize="xs"
                textDecoration="underline"
                color="white">
                {siteSettings?.changeLanguageText}
              </Box>
              <ChevronDownIcon boxSize={5} color="white" ml={1} />
            </AccordionButton>
            <AccordionPanel py={6} px={0}>
              <LocaleList locales={allLocale.nodes} />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Show>
      <Hide below="lg">
        {flagImageValid === true && (
          <Popover className="popover" isOpen={isOpen} onClose={onClose}>
            <Box
              as="button"
              display="flex"
              alignItems="center"
              m="0 0 0 auto"
              width="100%"
              justifyContent="flex-end"
              onClick={onToggle}>
              <PopoverTrigger>
                <AspectRatio
                  boxSize="1.25rem"
                  ratio={1}
                  display="inline-block"
                  verticalAlign="middle"
                  borderRadius="full"
                  mr={1}
                  mt="-0.0625rem"
                  overflow="hidden">
                  <Image
                    src={flags !== null && flags(`./${locale}.svg`).default}
                    alt={locale}
                  />
                </AspectRatio>
              </PopoverTrigger>
              <Box
                as="span"
                fontSize="xs"
                color={light ? `#fff` : `primary.dark-blue`}
                textTransform="uppercase"
                mr="4">
                {locale}
              </Box>
              <Box
                as="span"
                fontWeight="800"
                fontSize="xs"
                textDecoration="underline"
                color={light ? `#fff` : `secondary.pink`}>
                {siteSettings.changeLanguageText !== null &&
                  siteSettings.changeLanguageText}
              </Box>
            </Box>
            <PopoverContent
              width="100%"
              minWidth="12.125rem"
              borderRadius="xs"
              p={6}
              mt={1}>
              <PopoverArrow />
              <PopoverBody p={0}>
                <LocaleList locales={allLocale.nodes} />
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )}
      </Hide>
    </Flex>
  );
};

export default LocaleOptions;
