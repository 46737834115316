import React from 'react';
import { Icon } from '@chakra-ui/react';

const LinkedInIcon = (props) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M21 14.0076V20.9575H17.1429V14.4754C17.1429 12.8507 16.5877 11.7363 15.1851 11.7363C14.7705 11.7297 14.3635 11.8658 14.0152 12.1277C13.6669 12.3895 13.3928 12.7653 13.2273 13.2079C13.1332 13.5249 13.0911 13.8596 13.1031 14.1947V21H9.21672C9.21672 21 9.26786 10.018 9.21672 8.87807H13.0812V10.5794C13.4613 9.92267 13.982 9.39283 14.5919 9.04223C15.2017 8.69164 15.8796 8.53243 16.5584 8.58034C19.0714 8.55482 21 10.2902 21 14.0076ZM5.19156 3C3.86201 3 3 3.9017 3 5.09263C3 6.28355 3.8401 7.18526 5.13312 7.18526H5.19156C6.53571 7.18526 7.38312 6.25803 7.38312 5.09263C7.38312 3.92722 6.50649 3 5.19156 3ZM3.23377 20.966H7.09091V8.84405H3.23377V20.966Z" />
  </Icon>
);

export default LinkedInIcon;
