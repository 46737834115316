import React from 'react';
import { graphql, useStaticQuery, Link as GatsbyLink } from 'gatsby';
import {
  Box,
  Stack,
  Flex,
  Heading,
  Text,
  Image as ChakraImage
} from '@chakra-ui/react';
import FooterNav from '@components/molecules/FooterNav';
import Wrapper from '@components/atoms/Wrapper';
import RoundEdge from '@components/atoms/RoundEdge';
import ButtonCms from '@components/atoms/ButtonCms';
import Image from '@components/atoms/Image';
import logo from '@assets/images/froneri-logo.svg';

const Footer = () => {
  const {
    site: {
      siteMetadata: { title: siteTitle }
    },
    siteSettings: { siteLogo: customSiteLogo = null },
    strapiFooter
  } = useStaticQuery(graphql`
    query ($locale: String) {
      site {
        siteMetadata {
          title
        }
      }
      strapiFooter(locale: { eq: $locale }) {
        title
        text
        cta {
          title
          link
          type
        }
        copyright
      }
      siteSettings: strapiSiteSetting {
        siteLogo {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
            url
          }
          alternativeText
        }
      }
    }
  `);

  if (!strapiFooter) {
    return null;
  }

  const { title, text, cta, copyright } = strapiFooter;

  const hasCustomSiteLogo =
    typeof customSiteLogo !== `undefined` && customSiteLogo !== null;

  return (
    <Box as="footer" bg="primary.dark-blue" color="white">
      <RoundEdge type="bottom" />
      <Wrapper>
        <Stack
          direction={{ base: `column`, md: `row` }}
          spacing={{ base: 10, md: 16, xl: `6%` }}
          pt={{ base: 12, md: 28 }}
          pb={{ base: 10, md: 14 }}>
          <Box width={{ md: `36%`, xl: `22%` }}>
            {title && (
              <Heading color="white" mb="4">
                {title}
              </Heading>
            )}
            {text && (
              <Text fontSize="sm" color="secondary.pale-blue" mb="5">
                {text}
              </Text>
            )}
            {cta && <ButtonCms data={cta} color="white" />}
          </Box>
          <FooterNav />
        </Stack>
        <Flex
          direction={{ base: `column`, md: `row` }}
          alignItems={{ md: `center` }}
          justifyContent={{ md: `space-between` }}
          pt={{ base: 10, md: 12 }}
          pb="12"
          borderTop="1px"
          borderColor="secondary.mid-blue">
          <Flex
            as={GatsbyLink}
            to="/"
            width={{ base: `8rem`, lg: `11.25rem` }}
            mb={{ base: 6, md: 0 }}>
            {hasCustomSiteLogo === true ? (
              <Box w="100%">
                <Image image={customSiteLogo} />
              </Box>
            ) : (
              <ChakraImage
                src={logo}
                alt={siteTitle}
                transform={{ md: `translateY(16%)` }}
              />
            )}
          </Flex>
          {copyright && (
            <Text fontSize="sm" mb="0">
              {copyright}
            </Text>
          )}
        </Flex>
      </Wrapper>
    </Box>
  );
};

export default Footer;
