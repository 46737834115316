import React, { useEffect, useRef, useState, useCallback } from 'react';
import {
  Box,
  Heading,
  Input,
  InputGroup,
  InputRightElement
} from '@chakra-ui/react';
import { Wrapper as GoogleMapsWrapper } from '@googlemaps/react-wrapper';
import Section from '@components/atoms/Section';
import Wrapper from '@components/atoms/Wrapper';
import { SearchIcon } from '@components/atoms/icons';
import SearchableMarkerMap from '@components/molecules/SearchableMarkerMap';
import { cmsUrl } from '@helpers/environment';
import instagramIcon from '@assets/images/instagram.svg';
import facebookIcon from '@assets/images/facebook.svg';

const createInfoWindowContentString = (
  name,
  address,
  instagramUrl,
  facebookUrl
) => {
  let contentString = `<div style="display:flex;flex-direction:column;gap:.5rem;width:14rem;"><p><strong>${name}</strong></p><p>${address}</p>`;

  if (instagramUrl || facebookUrl) {
    contentString += `<p style="display:flex;">`;
    contentString += instagramUrl
      ? `<a href="${instagramUrl}" rel="nofollow" target="_blank"><img src="${instagramIcon}" loading="lazy" width="24" height="24" alt="Instagram"/></a>`
      : ``;
    contentString += facebookUrl
      ? `<a href="${facebookUrl}" rel="nofollow" target="_blank"><img src="${facebookIcon}" loading="lazy" width="24" height="24" alt="Facebook"/></a>`
      : ``;
    contentString += `</p>`;
  }

  contentString += `</div>`;

  return contentString;
};

const KioskMap = ({ data: { title, tagline, centerLat, centerLng, zoom } }) => {
  const [markers, setMarkers] = useState(null);
  const [visibleMarkers, setVisibleMarkers] = useState(null);
  const [query, setQuery] = useState(``);
  const inputRef = useRef(null);
  const centerRef = useRef({
    lat: centerLat ? +centerLat : 0,
    lng: centerLng ? +centerLng : 0
  });

  // Performs search on each kiosk address and applys the results.
  const applySearch = useCallback(() => {
    if (inputRef.current === null) {
      return;
    }

    const searchQuery = query.trim().toLowerCase();

    if (searchQuery.length === 0) {
      setVisibleMarkers(null);
      return;
    }

    const searchMatches = markers
      .map((_, index) => index)
      .filter((index) => {
        return (
          markers[index].title?.toLowerCase().indexOf(searchQuery) > -1 ||
          markers[index].address?.toLowerCase().indexOf(searchQuery) > -1
        );
      });

    setVisibleMarkers(searchMatches);
  }, [markers, query]);

  // Fetch kiosk data.
  useEffect(() => {
    const fetchKiosks = async () => {
      const response = await fetch(`${cmsUrl}/uploads/kiosks.json`);
      const json = await response.json();
      const data = json.map((kiosk) => {
        const {
          kioskName,
          addressString,
          lat,
          lng,
          instagramUrl,
          facebookUrl
        } = kiosk;
        const contentString = createInfoWindowContentString(
          kioskName,
          addressString,
          instagramUrl,
          facebookUrl
        );
        return {
          title: kioskName,
          position: {
            lat: +lat,
            lng: +lng
          },
          address: addressString,
          infoWindowContent: contentString
        };
      });
      setMarkers(data);
    };

    fetchKiosks();
  }, []);

  return (
    <Section>
      <Wrapper>
        <Box
          display={{ md: `flex` }}
          alignItems={{ md: `end` }}
          justifyContent={{ md: `space-between` }}
          mb={{ base: 6, md: 10 }}>
          <Box pr="4" mb={{ base: 2, md: 0 }}>
            {tagline && (
              <Heading
                as="p"
                variant="detail"
                size="xs"
                color="secondary.mid-grey">
                {tagline}
              </Heading>
            )}
            {title && (
              <Heading as="h2" size="3xl" mb="0" lineHeight="2.3rem">
                {title}
              </Heading>
            )}
          </Box>
          <InputGroup maxWidth={{ md: `20rem` }}>
            <Input
              ref={inputRef}
              placeholder="Search for city"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              onKeyUp={applySearch}
            />
            <InputRightElement as="button" onClick={applySearch}>
              <SearchIcon boxSize="6" />
            </InputRightElement>
          </InputGroup>
        </Box>
        <GoogleMapsWrapper apiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY}>
          {markers !== null && (
            <SearchableMarkerMap
              markers={markers}
              visibleMarkers={visibleMarkers}
              center={centerRef.current}
              zoom={zoom}
            />
          )}
        </GoogleMapsWrapper>
      </Wrapper>
    </Section>
  );
};

export default KioskMap;
