import React from 'react';
import { Heading } from '@chakra-ui/react';
import Section from '@components/atoms/Section';
import Button from '@components/atoms/Button';
import { ArrowRightIcon } from '@components/atoms/icons';

const FormStatus = ({
  responseText,
  sendingMessageText,
  sendAgainText,
  formDidError,
  submitInProgress,
  submitSuccess,
  formErrorText,
  hideButton = false,
  onClick = () => {},
  ...rest
}) => {
  return (
    <Section m="0" {...rest}>
      {submitInProgress && (
        <Heading as="p" size="md" color="primary.dark-blue" mt="0" mb="0">
          {sendingMessageText}
        </Heading>
      )}
      {submitSuccess && (
        <>
          <Heading as="h2" size="2xl" color="primary.dark-blue" mt="6" mb="12">
            {responseText}
          </Heading>
          {hideButton === false && (
            <Button rightIcon={<ArrowRightIcon />} onClick={onClick}>
              {sendAgainText}
            </Button>
          )}
        </>
      )}
      {formDidError && (
        <>
          <Heading as="p" size="md" color="red" mt="0" mb="0">
            {formErrorText || `Form errored.`}
          </Heading>
          {hideButton === false && (
            <Button rightIcon={<ArrowRightIcon />} onClick={onClick}>
              {sendAgainText}
            </Button>
          )}
        </>
      )}
    </Section>
  );
};

export default FormStatus;
