export const components = {
  Heading: {
    baseStyle: {
      color: `primary.dark-blue`,
      mb: { base: 4, md: 6 }
    },
    sizes: {
      xs: {
        fontSize: [`xs`, `xs`, `sm`]
      },
      sm: {
        fontSize: [`sm`, `sm`, `md`]
      },
      md: {
        fontSize: [`lg`, `lg`, `xl`]
      },
      lg: {
        fontSize: [`xl`, `xl`, `2xl`]
      },
      xl: {
        fontSize: `3xl`
      },
      '2xl': {
        fontSize: [`3xl`, `3xl`, `4xl`]
      },
      '3xl': {
        fontSize: [`3xl`, `3xl`, `5xl`]
      },
      '4xl': {
        fontSize: [`4xl`, `4xl`, `5xl`, `6xl`]
      },
      '5xl': {
        fontSize: [`4xl`, `4xl`, `6xl`, `8xl`],
        lineHeight: `none`
      }
    },
    variants: {
      detail: {
        fontFamily: `body`,
        fontWeight: `600`,
        textTransform: `uppercase`,
        letterSpacing: `.2em`
      }
    }
  },
  Text: {
    baseStyle: {
      mb: { base: 4, md: 6 }
    }
  },
  List: {
    baseStyle: {
      container: {
        mb: { base: 4, md: 6 }
      }
    }
  },
  Button: {
    defaultProps: {
      colorScheme: null,
      variant: `primary`,
      size: { base: `sm`, md: `md` }
    },
    baseStyle: {
      fontFamily: `heading`,
      fontWeight: `600`,
      borderRadius: `xs`
    },
    sizes: {
      ellipsed: {
        w: 3,
        h: 3
      },
      sm: ({ variant }) => ({
        h: variant === `primary` ? 12 : null,
        px: variant === `primary` ? 6 : null,
        minW: null
      }),
      md: ({ variant }) => ({
        h: variant === `primary` ? 14 : null,
        px: variant === `primary` ? 6 : null,
        minW: null,
        fontSize: `md`
      })
    },
    variants: {
      secondary: {
        borderRadius: null
      }
    }
  },
  Link: {
    baseStyle: {
      _hover: {
        textDecoration: `none`
      }
    },
    variants: {
      header: {
        color: `secondary.pink`,
        fontWeight: `bold`,
        textDecoration: `underline`,
        _hover: {
          textDecoration: `underline`
        }
      }
    }
  },
  Radio: {
    baseStyle: {
      control: {
        borderWidth: `1px`,
        borderColor: `primary.dark-blue`,
        _checked: {
          background: `primary.dark-blue`,
          borderColor: `primary.dark-blue`,
          _hover: {
            background: `primary.dark-blue`,
            borderColor: `primary.dark-blue`
          }
        }
      },
      label: {
        fontFamily: `heading`,
        fontWeight: `bold`,
        color: `secondary.pink`,
        textDecoration: `underline`
      }
    },
    sizes: {
      md: {
        control: {
          w: 6,
          h: 6
        }
      }
    }
  },
  Checkbox: {
    baseStyle: {
      control: {
        borderWidth: `1px`,
        borderColor: `primary.dark-blue`,
        borderRadius: 4,
        _checked: {
          background: `primary.dark-blue`,
          borderColor: `primary.dark-blue`,
          _hover: {
            background: `primary.dark-blue`,
            borderColor: `primary.dark-blue`
          }
        }
      },
      label: {
        fontFamily: `heading`,
        fontWeight: `bold`,
        color: `secondary.pink`,
        textDecoration: `underline`
      }
    },
    sizes: {
      md: {
        control: {
          w: 6,
          h: 6
        }
      }
    }
  }
};
