import { useStaticQuery, graphql } from 'gatsby';

export const useApplyForm = () => {
  const strapiApplyForm = useStaticQuery(
    graphql`
      {
        strapiApplyForm {
          formId: strapi_id
          title
          defaultPlaceholder
          defaultErrorMessage
          defaultSubject
          fromAddress
          toAddress
          responseText
          sendingMessageText
          submitButtonText
          fieldGroups {
            id
            label
            fields: strapi_fields {
              id
              fieldType
              label
              required
              dataId
              errorMessage
              placeholder
            }
          }
        }
      }
    `
  );
  return strapiApplyForm;
};
