import React, { useCallback, useMemo } from 'react';
import {
  Box,
  AspectRatio,
  Image,
  ListItem,
  UnorderedList
} from '@chakra-ui/react';
import dataLayerPush from '@helpers/dataLayerPush';
import { locale, siteUrl, isLocalBuild } from '@helpers/environment';
import Link from '@components/atoms/Link';

const LocaleList = ({ locales }) => {
  const flags = useMemo(() => {
    return isLocalBuild
      ? null
      : require.context(`../../assets/images/flags`, true, /\.svg$/);
  }, []);

  const handleLocationChange = useCallback((langcode) => {
    dataLayerPush({
      event: `location_link`,
      interaction: {
        click_text: `Change language`,
        location: langcode
      }
    });
  }, []);

  // Must use origin because some markets have a language path in the site url eg. UK
  const { origin } = new URL(siteUrl);

  return (
    <UnorderedList listStyleType="none" ml={0} mb={0}>
      {locales.map((localeItem) => {
        const isCurrentLocale = localeItem.code === locale;
        const currentFlag =
          flags !== null ? flags(`./${locale}.svg`).default : null;

        const flagImageValid =
          flags !== null &&
          typeof currentFlag !== `undefined` &&
          currentFlag !== null;

        const splitAtSpace = localeItem.name.split(` `);

        const localeNameOnly = splitAtSpace
          .slice(0, splitAtSpace.length - 1)
          .join(` `);

        const localeCodeOnly = splitAtSpace.pop();

        return (
          <ListItem key={`locale-list-item-${localeItem.code}`}>
            <Link
              href={`${origin}/${localeItem.code}`}
              display="flex"
              alignItems="center"
              width="100%"
              p={2}
              mb={1}
              mr={0}
              color={{
                base: `white`,
                lg: isCurrentLocale ? `white` : `primary.dark-blue`
              }}
              fontFamily="heading"
              fontSize="sm"
              borderRadius="xs"
              backgroundColor={
                isCurrentLocale ? `secondary.pink` : `transparent`
              }
              cursor="pointer"
              onClick={() => handleLocationChange(localeItem.code)}
              _hover={{
                backgroundColor: isCurrentLocale
                  ? `secondary.pink`
                  : `secondary.chakra-grey-custom`,
                color: isCurrentLocale ? `white` : `primary.dark-blue`
              }}>
              {flagImageValid === true && (
                <AspectRatio
                  boxSize="1rem"
                  ratio="1"
                  borderRadius="full"
                  border="1px solid white"
                  mr={1}
                  overflow="hidden">
                  <Image
                    src={
                      flags !== null &&
                      flags(`./${localeItem.code}.svg`).default
                    }
                    alt={localeItem.name}
                  />
                </AspectRatio>
              )}
              <Box as="span">{localeNameOnly}</Box>&nbsp;
              <Box as="span" textTransform="uppercase">
                {localeCodeOnly}
              </Box>
            </Link>
          </ListItem>
        );
      })}
    </UnorderedList>
  );
};

export default LocaleList;
