import React, { useState, useCallback, forwardRef, useEffect } from 'react';
import {
  Box,
  Flex,
  Heading,
  Stack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  RadioGroup,
  Radio,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  CheckboxGroup,
  Checkbox,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  useBreakpointValue,
  useDisclosure,
  Input,
  InputGroup,
  InputRightElement
} from '@chakra-ui/react';
import {
  ChevronUpIcon,
  ChevronDownIcon,
  ArrowRightIcon,
  FilterIcon,
  CloseIcon,
  CheckIcon,
  SearchIcon
} from '@components/atoms/icons';
import Button from '@components/atoms/Button';
import { graphql, useStaticQuery } from 'gatsby';

const FilterBar = forwardRef(
  (
    {
      filters = [],
      activeValues,
      initialQuery,
      title,
      tagline,
      onSubmitFilter,
      onSubmitQuery,
      searchEnabled = true,
      ...props
    },
    ref
  ) => {
    const { strapiSearchAndFilters } = useStaticQuery(graphql`
      {
        strapiSearchAndFilters {
          filterText
          applyText
          searchText
        }
      }
    `);
    const translations = strapiSearchAndFilters || {};
    const isDesktop = useBreakpointValue({ base: false, md: true });
    const {
      isOpen: isModalOpen,
      onOpen: onModalOpen,
      onClose: onModalClose
    } = useDisclosure();
    const [selectedValues, setSelectedValues] = useState(activeValues);
    const [query, setQuery] = useState(``);

    /**
     * Updates the selected values when the user changes a filter.
     */
    const changeFilter = useCallback(
      (name, value) => {
        setSelectedValues({ ...selectedValues, [name]: value });
      },
      [selectedValues]
    );

    const handleOnInputKey = useCallback(
      ({ key }) => {
        if (key.toLowerCase() === `enter`) {
          onSubmitQuery(query);
        }
      },
      [query, onSubmitQuery]
    );

    useEffect(() => {
      setQuery(initialQuery);
    }, [initialQuery]);

    return (
      <Flex
        ref={ref}
        direction={{ base: `column`, md: `row` }}
        align={{ md: `end` }}
        justify="space-between"
        mb={{ base: 6, md: 12 }}
        {...props}>
        <Box pr={{ base: 4, md: 0 }} mb={{ base: 4, md: 0 }}>
          {tagline && (
            <Heading
              as="p"
              variant="detail"
              size="xs"
              color="secondary.mid-grey">
              {tagline}
            </Heading>
          )}
          {title && (
            <Heading as="h2" size="3xl" mb="0">
              {title}
            </Heading>
          )}
        </Box>
        <Stack
          direction={{ base: `column`, md: `row` }}
          align={{ md: `center` }}
          spacing="4">
          {searchEnabled && (
            <InputGroup>
              <Input
                placeholder={translations.searchText}
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onKeyDown={handleOnInputKey}
              />
              <InputRightElement
                as="button"
                onClick={() => onSubmitQuery(query)}>
                <SearchIcon boxSize="6" />
              </InputRightElement>
            </InputGroup>
          )}
          {!isDesktop && filters.length > 0 && (
            <>
              {filters.length > 1
                ? filters.map(({ id, label }) => {
                    return (
                      <Button
                        key={`filter-label-${id}`}
                        onClick={() => {
                          setSelectedValues(activeValues);
                          onModalOpen();
                        }}
                        rightIcon={<FilterIcon />}>
                        {label}
                      </Button>
                    );
                  })
                : translations.filterText && (
                    // eslint-disable-next-line react/jsx-indent
                    <Button
                      onClick={() => {
                        setSelectedValues(activeValues);
                        onModalOpen();
                      }}
                      rightIcon={<FilterIcon />}>
                      {translations.filterText}
                    </Button>
                  )}
              <Modal isOpen={isModalOpen} onClose={onModalClose}>
                <ModalOverlay bg="rgb(0, 28, 73, .7)" />
                <ModalContent
                  maxWidth="100%"
                  mt="0"
                  borderRadius="none"
                  borderBottomLeftRadius="sm"
                  borderBottomRightRadius="sm">
                  <Flex
                    as="button"
                    position="fixed"
                    top="1"
                    right="1"
                    w="34px"
                    h="34px"
                    m="4"
                    justify="center"
                    align="center"
                    bg="primary.dark-blue"
                    borderRadius="xs"
                    onClick={onModalClose}
                    aria-label="Close"
                    zIndex="modal">
                    <CloseIcon color="white" boxSize="6" />
                  </Flex>
                  <ModalBody py="16" px="5">
                    <Accordion allowMultiple>
                      {filters.map((f) => {
                        const FilterGroup =
                          f.type === `checkbox` ? CheckboxGroup : RadioGroup;
                        const FilterInput =
                          f.type === `checkbox` ? Checkbox : Radio;
                        return (
                          <AccordionItem
                            key={`filter-bar-item-${f.id}`}
                            border="none"
                            mb="10"
                            sx={{
                              '.chakra-collapse': {
                                px: 1,
                                mx: -1
                              }
                            }}>
                            {({ isExpanded }) => (
                              <>
                                <AccordionButton
                                  fontSize="2xl"
                                  fontFamily="heading"
                                  lineHeight="none"
                                  color="primary.dark-blue"
                                  p="0"
                                  _hover={{ bg: `none` }}>
                                  {f.label}
                                  {isExpanded === true ? (
                                    <ChevronUpIcon
                                      ml="1"
                                      color="secondary.pink"
                                      boxSize="4"
                                    />
                                  ) : (
                                    <ChevronDownIcon
                                      ml="1"
                                      color="primary.froneri-blue"
                                      boxSize="4"
                                    />
                                  )}
                                </AccordionButton>
                                <AccordionPanel pt="4" pb="1" px="0">
                                  <FilterGroup
                                    type={f.type}
                                    value={selectedValues[f.name]}
                                    onChange={(value) => {
                                      changeFilter(f.name, value);
                                    }}>
                                    <Stack spacing="3">
                                      {f.items.map(({ value, label }) => (
                                        <FilterInput
                                          key={`filter-${f.name}-${value}`}
                                          value={value}
                                          icon={<CheckIcon boxSize="4" />}>
                                          {label}
                                        </FilterInput>
                                      ))}
                                    </Stack>
                                  </FilterGroup>
                                </AccordionPanel>
                              </>
                            )}
                          </AccordionItem>
                        );
                      })}
                    </Accordion>
                    {translations.applyText && (
                      <Button
                        rightIcon={<ArrowRightIcon />}
                        onClick={() => {
                          if (typeof onSubmitFilter === `function`) {
                            onSubmitFilter(selectedValues, query);
                          }
                          onModalClose();
                        }}>
                        {translations.applyText}
                      </Button>
                    )}
                  </ModalBody>
                </ModalContent>
              </Modal>
            </>
          )}
          {isDesktop && filters.length > 0 && (
            <Stack direction="row-reverse" spacing="4">
              <Menu
                autoSelect={false}
                onOpen={() => setSelectedValues(activeValues)}>
                {({ isOpen }) => {
                  let Icon = FilterIcon;
                  if (isOpen) {
                    Icon = CloseIcon;
                  }
                  return (
                    <>
                      <MenuButton width={{ base: `50%`, md: `auto` }}>
                        <Button
                          as="span"
                          rightIcon={<Icon />}
                          width={{ base: `100%`, md: `auto` }}>
                          {translations.filterText}
                        </Button>
                      </MenuButton>
                      <MenuList
                        borderRadius="xs"
                        border="none"
                        boxShadow="2xl"
                        py="4"
                        my="3"
                        position="relative">
                        <Box
                          width="0"
                          height="0"
                          borderLeft="10px solid transparent"
                          borderRight="10px solid transparent"
                          borderBottom="10px solid #fff"
                          position="absolute"
                          sx={{
                            '[data-popper-placement="top-start"] &': {
                              top: `100%`,
                              left: `10%`,
                              transform: `rotate(-180deg)`
                            },
                            '[data-popper-placement="top-end"] &': {
                              top: `100%`,
                              right: `10%`,
                              transform: `rotate(-180deg)`
                            },
                            '[data-popper-placement="bottom-start"] &': {
                              bottom: `100%`,
                              left: `10%`
                            },
                            '[data-popper-placement="bottom-end"] &': {
                              bottom: `100%`,
                              right: `10%`
                            }
                          }}
                        />
                        <Flex>
                          {filters.map((f) => {
                            const menuOptionValue =
                              selectedValues !== null
                                ? selectedValues[f.name]
                                : null;

                            return (
                              menuOptionValue && (
                                <MenuOptionGroup
                                  key={`filter-${f.name}`}
                                  type={f.type}
                                  value={selectedValues[f.name]}
                                  onChange={
                                    (value) => changeFilter(f.name, value)
                                    // eslint-disable-next-line react/jsx-curly-newline
                                  }>
                                  <Heading as="h5" size="sm" mb="0" pl="6">
                                    {`${f.name
                                      .charAt(0)
                                      .toUpperCase()}${f.name.slice(1)}`}
                                    :
                                  </Heading>
                                  {f.items.map(({ value, label }) => (
                                    <MenuItemOption
                                      key={`filter-${f.name}-${value}`}
                                      position="relative"
                                      px="6"
                                      py="2"
                                      fontFamily="heading"
                                      fontWeight="bold"
                                      color="secondary.pink"
                                      textDecoration="underline"
                                      value={value}
                                      closeOnSelect={false}
                                      icon={
                                        f.type === `checkbox` ? (
                                          <CheckIcon
                                            color="white"
                                            boxSize="4"
                                          />
                                        ) : (
                                          <Box
                                            as="span"
                                            background="white"
                                            width="3"
                                            height="3"
                                            borderRadius="100%"
                                          />
                                        )
                                      }
                                      iconSpacing="0"
                                      _before={{
                                        content: `""`,
                                        display: `block`,
                                        position: `relative`,
                                        width: 6,
                                        height: 6,
                                        borderWidth: `1px`,
                                        borderColor: `primary.dark-blue`,
                                        borderStyle: `solid`,
                                        borderRadius:
                                          f.type === `radio` ? `100%` : 4,
                                        mr: 2
                                      }}
                                      _checked={{
                                        _before: {
                                          background: `primary.dark-blue`
                                        }
                                      }}
                                      sx={{
                                        '.chakra-menu__icon-wrapper': {
                                          position: `absolute`,
                                          top: `50%`,
                                          left: 6,
                                          transform: `translateY(-50%)`,
                                          display: `flex`,
                                          alignItems: `center`,
                                          justifyContent: `center`,
                                          width: 6,
                                          height: 6
                                        }
                                      }}>
                                      {label}
                                    </MenuItemOption>
                                  ))}
                                </MenuOptionGroup>
                              )
                            );
                          })}
                        </Flex>
                        {translations.applyText && (
                          <MenuItem
                            px="6"
                            py="3"
                            onClick={() => {
                              if (typeof onSubmitFilter === `function`) {
                                onSubmitFilter(selectedValues, query);
                              }
                            }}>
                            <Button as="span" rightIcon={<ArrowRightIcon />}>
                              {translations.applyText}
                            </Button>
                          </MenuItem>
                        )}
                      </MenuList>
                    </>
                  );
                }}
              </Menu>
            </Stack>
          )}
        </Stack>
      </Flex>
    );
  }
);

export default FilterBar;
