import React from 'react';
import { Icon } from '@chakra-ui/react';

const MapIcon = (props) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9412 4.75C10.9383 4.75 10.0441 5.63202 10.0441 6.82353C10.0441 7.38023 10.2778 7.93204 10.7294 8.55751C11.063 9.01951 11.4772 9.47257 11.9412 9.96554C12.4051 9.47257 12.8194 9.01951 13.153 8.55751C13.6045 7.93204 13.8382 7.38023 13.8382 6.82353C13.8382 5.63202 12.944 4.75 11.9412 4.75ZM8.54412 6.82353C8.54412 4.89625 10.0202 3.25 11.9412 3.25C13.8622 3.25 15.3382 4.89625 15.3382 6.82353C15.3382 7.82622 14.9102 8.68618 14.3691 9.43557C13.9205 10.057 13.346 10.6642 12.7946 11.247C12.6914 11.3562 12.589 11.4644 12.4883 11.5718C12.3465 11.723 12.1485 11.8088 11.9412 11.8088C11.7339 11.8088 11.5358 11.723 11.394 11.5718C11.2934 11.4644 11.191 11.3562 11.0877 11.247C10.5364 10.6643 9.9619 10.057 9.51325 9.43557C8.97219 8.68618 8.54412 7.82622 8.54412 6.82353ZM3.6057 4.24436C3.82681 4.10771 4.10292 4.09529 4.33541 4.21153L6.10012 5.09389C6.4706 5.27913 6.62077 5.72963 6.43553 6.10012C6.25028 6.4706 5.79978 6.62077 5.4293 6.43553L4.75 6.09588V15.8894L8.54412 17.7865V13.7059C8.54412 13.2917 8.8799 12.9559 9.29412 12.9559C9.70833 12.9559 10.0441 13.2917 10.0441 13.7059V17.7865L13.8382 15.8894V13.7059C13.8382 13.2917 14.174 12.9559 14.5882 12.9559C15.0024 12.9559 15.3382 13.2917 15.3382 13.7059V15.8894L19.1324 17.7865V7.99294L17.7822 7.31788C17.4118 7.13264 17.2616 6.68213 17.4468 6.31165C17.6321 5.94116 18.0826 5.791 18.4531 5.97624L20.2178 6.85859C20.4719 6.98564 20.6324 7.24533 20.6324 7.52941V19C20.6324 19.2599 20.4978 19.5013 20.2767 19.638C20.0555 19.7746 19.7794 19.7871 19.5469 19.6708L14.5882 17.1915L9.62953 19.6708C9.41838 19.7764 9.16985 19.7764 8.95871 19.6708L3.66459 17.0238C3.4105 16.8967 3.25 16.637 3.25 16.3529V4.88235C3.25 4.62242 3.38459 4.38102 3.6057 4.24436ZM11.1912 6.64706C11.1912 6.23285 11.527 5.89706 11.9412 5.89706H11.95C12.3642 5.89706 12.7 6.23285 12.7 6.64706C12.7 7.06127 12.3642 7.39706 11.95 7.39706H11.9412C11.527 7.39706 11.1912 7.06127 11.1912 6.64706Z"
    />
  </Icon>
);

export default MapIcon;
