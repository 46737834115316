import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { HStack, Box, Tooltip, useBreakpointValue } from '@chakra-ui/react';
import { ChevronRightIcon } from '@components/atoms/icons';
import Wrapper from '@components/atoms/Wrapper';
import SuspenseLoader from '@components/atoms/SuspenseLoader';
import SuspenseHelper from '@components/atoms/SuspenseHelper';

const truncateString = (str, num = 36) => {
  if (str === null || str.length <= num) {
    return str;
  }

  return `${str.slice(0, num)}...`;
};

const Breadcrumbs = ({
  id,
  crumbs = [],
  labelOverride = null,
  wrapperProps,
  ...props
}) => {
  const truncateBy = useBreakpointValue({ base: 10, sm: 30, md: 50 });

  return (
    <Box background="white" pos="relative" pb={{ base: 8, md: 0 }} {...props}>
      <Wrapper {...wrapperProps}>
        <HStack>
          <SuspenseHelper
            fallback={<SuspenseLoader loadingText="Baking breadcrumbs..." />}>
            {crumbs.length > 0 &&
              crumbs.map(({ path, slug, label }, index) => {
                return index < crumbs.length - 1 ? (
                  <Box
                    key={`${id}-${slug}`}
                    as={GatsbyLink}
                    to={path}
                    color="primary.dark-blue"
                    _hover={{
                      color: `secondary.pink`
                    }}>
                    <Tooltip label={label}>{truncateString(label)}</Tooltip>
                    {` `}
                    <ChevronRightIcon />
                  </Box>
                ) : (
                  <Box key={`${id}-${slug}`} color="secondary.mid-blue">
                    <Tooltip label={labelOverride || label}>
                      {truncateString(labelOverride || label, truncateBy)}
                    </Tooltip>
                  </Box>
                );
              })}
          </SuspenseHelper>
        </HStack>
      </Wrapper>
    </Box>
  );
};

export default Breadcrumbs;
