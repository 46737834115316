import React from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import RoundEdge from '@components/atoms/RoundEdge';
import Wrapper from '@components/atoms/Wrapper';
import ButtonCms from '@components/atoms/ButtonCms';
import Card from '@components/molecules/Card';
import HeadlineAndCallToAction from '@components/molecules/HeadlineAndCallToAction';
import getColorFromField from '@helpers/getColorFromField';

const BrandCardsRow = ({
  data: { title, tagline, rowmode, cta = null, cards = [], brandColor }
}) => {
  const bgColor = getColorFromField(brandColor) || `primary.light-grey`;
  const isEven = rowmode === `even`;
  return (
    <Box as="section" position="relative" bg={bgColor} mb={{ md: 10 }}>
      <Box position="relative" pb={{ base: 4, md: 14 }}>
        <Wrapper>
          <HeadlineAndCallToAction
            title={title}
            tagline={tagline}
            cta={cta && <ButtonCms data={cta} dataLayerType="section_title" />}
            mb={{ base: 6, md: 10 }}
          />
          <SimpleGrid
            columns={{ base: 1, md: isEven ? 2 : 3 }}
            spacing="12"
            h="100%">
            {cards &&
              cards.length > 0 &&
              cards.map((cardData) => (
                <Card
                  key={`brand-cards-row-${cardData.id}`}
                  data={{ strapiComponent: `elements.brand-card`, ...cardData }}
                  alignButton="end"
                  aspectRatio3By2={!!isEven}
                  maxWidth={isEven ? `none` : `31.25rem`}
                  sx={{
                    height: `100%`,
                    '.card-content': {
                      height: `100%`,
                      display: `flex`
                    },
                    '.card-cta-wrapper': {
                      display: `flex`
                    },
                    '.card-cta': {
                      marginTop: `auto`
                    }
                  }}
                />
              ))}
          </SimpleGrid>
        </Wrapper>
      </Box>
      <RoundEdge type="top" position="relative" />
    </Box>
  );
};

export default BrandCardsRow;
