import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Box, Flex, Heading } from '@chakra-ui/react';
import Wrapper from '@components/atoms/Wrapper';
import Button from '@components/atoms/Button';
import FormStatus from '@components/organisms/FormStatus';
import FormFieldText from '@components/molecules/FormFieldText';
import FormFieldEmail from '@components/molecules/FormFieldEmail';
import { ArrowRightIcon } from '@components/atoms/icons';
import { postForm } from '@helpers/formHelpers';

const filterElements = (elements) => {
  return Array.from(elements).filter((element) => {
    if (
      element.type !== `submit` &&
      (element.id === null || element.id?.length === 0)
    ) {
      throw new Error(`ID could not be found on element:`, element);
    }

    return (
      element.id !== null && element.id.length > 0 && element.type !== `submit`
    );
  });
};

const CompetitionForm = ({ data: { competition } }) => {
  const {
    competitionId,
    startDate,
    endDate,
    locale,
    name,
    code,
    email,
    firstName,
    lastName,
    errorGeneral,
    submitText,
    loadingText,
    compEndedText,
    compNotStartedText,
    success: successText
  } = competition;

  const [formValid, setFormValid] = useState(false);
  const [submitAttempted, setSubmitAttempted] = useState(false);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [formDidError, setFormDidError] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);
  const [codeError, setCodeError] = useState(code.missingError);

  const compOccurring = useRef(null);
  useEffect(() => {
    const start = new Date(startDate).getTime();
    const present = new Date().getTime();
    const end = new Date(endDate).getTime();

    if (start <= present && present <= end) compOccurring.current = `present`;
    else if (present < start) compOccurring.current = `future`;
    else if (present > end) compOccurring.current = `past`;
  }, [endDate, startDate]);

  const handleOnFieldRendered = useCallback(({ fieldId, required }) => {
    if (required === true) {
      setInvalidFields((prev) => [...prev, fieldId]);
    }
  }, []);

  const handleOnFieldChange = useCallback(
    ({ fieldId, isValid }) => {
      const filtered = invalidFields.filter(
        (invalidFieldId) => invalidFieldId !== fieldId
      );

      if (isValid === false) {
        setInvalidFields([...filtered, fieldId]);
      } else {
        setInvalidFields(filtered);
      }
    },
    [invalidFields]
  );

  const handleOnFormSubmit = useCallback(
    async (event) => {
      event.preventDefault();

      const setCodeInvalid = (error) => {
        setCodeError(error);
        const filtered = invalidFields.filter(
          (invalidFieldId) => invalidFieldId !== code.label
        );
        setInvalidFields([...filtered, code.label]);
      };

      const filteredElements = filterElements(event.target.elements);

      setSubmitAttempted(true);
      setFormDidError(false);

      if (!filteredElements[3].value) setCodeInvalid(code.missingError);
      else if (filteredElements[3].value.length !== 5) {
        setCodeInvalid(code.invalidError);
      }

      if (formValid === true) {
        setSubmitInProgress(true);

        const { success, isCodeValid, isCodeUsed } = await postForm(
          `${process.env.GATSBY_CMS_URL}/api/competition-form-verify-code/`,
          {
            compId: competitionId,
            locale,
            code: filteredElements[3].value,
            externalEmailAddress: filteredElements[0].value,
            firstName: filteredElements[1].value,
            lastName: filteredElements[2].value
          },
          false
        );

        if (success && isCodeUsed) {
          setCodeInvalid(code.duplicateError);
        } else if (success && isCodeValid === false) {
          setCodeInvalid(code.invalidError);
        } else {
          setSubmitAttempted(false);
        }

        setSubmitInProgress(false);

        if (success && isCodeValid && !isCodeUsed) {
          setSubmitSuccess(true);
          return;
        }

        setFormDidError(!success);
      } else if (typeof window !== `undefined` && invalidFields.length > 0) {
        const [firstInvalidField] = invalidFields;
        const resolvedField = document.getElementById(firstInvalidField);

        if (resolvedField) {
          resolvedField.scrollIntoView({
            behavior: `smooth`,
            block: `center`,
            inline: `nearest`
          });
        }
      }
    },
    [
      code.duplicateError,
      code.invalidError,
      code.label,
      code.missingError,
      competitionId,
      formValid,
      invalidFields,
      locale
    ]
  );

  useEffect(() => {
    setFormValid(invalidFields.length === 0);
  }, [invalidFields]);

  return (
    <Wrapper maxWidth="64rem" mt="16">
      <Flex
        justifyContent="center"
        alignContent="center"
        py={{ base: 8, md: 12 }}
        px={{ base: 8, md: 12 }}
        bg="secondary.pale-blue"
        borderRadius="sm">
        {compOccurring.current === `present` ? (
          <>
            {submitSuccess ? (
              <Heading>{successText}</Heading>
            ) : (
              <Flex
                maxWidth={{ base: `none`, md: `25rem` }}
                flexDirection="column">
                <Heading>{name}</Heading>
                <Box
                  id={`competition-form-${competitionId}`}
                  as="form"
                  noValidate
                  onSubmit={handleOnFormSubmit}>
                  <FormFieldEmail
                    fieldId={email.label}
                    fieldLabel={email.label}
                    errorMessage={email.missingError}
                    onReady={handleOnFieldRendered}
                    onChange={handleOnFieldChange}
                    submitAttempted={submitAttempted}
                    isInvalid={invalidFields.includes(email.label)}
                    required
                  />
                  <FormFieldText
                    fieldId={firstName.label}
                    fieldLabel={firstName.label}
                    errorMessage={firstName.missingError}
                    onReady={handleOnFieldRendered}
                    onChange={handleOnFieldChange}
                    submitAttempted={submitAttempted}
                    required
                  />
                  <FormFieldText
                    fieldId={lastName.label}
                    fieldLabel={lastName.label}
                    errorMessage={lastName.missingError}
                    onReady={handleOnFieldRendered}
                    onChange={handleOnFieldChange}
                    submitAttempted={submitAttempted}
                    required
                  />
                  <FormFieldText
                    fieldId={code.label}
                    fieldLabel={code.label}
                    errorMessage={codeError}
                    onReady={handleOnFieldRendered}
                    onChange={handleOnFieldChange}
                    submitAttempted={submitAttempted}
                    isInvalid={invalidFields.includes(code.label)}
                    required
                  />
                  <Button
                    mt="8"
                    type="submit"
                    disabled={formValid === false || submitInProgress === true}
                    rightIcon={<ArrowRightIcon />}>
                    {submitText}
                  </Button>
                </Box>
                <FormStatus
                  responseText={successText}
                  sendingMessageText={loadingText}
                  formDidError={formDidError}
                  formErrorText={errorGeneral}
                  submitInProgress={submitInProgress}
                  submitSuccess={submitSuccess}
                  hideButton
                  mb="0"
                  mt="4"
                />
              </Flex>
            )}
          </>
        ) : (
          <Heading>
            {compOccurring.current === `past` && compEndedText}
            {compOccurring.current === `future` && compNotStartedText}
          </Heading>
        )}
      </Flex>
    </Wrapper>
  );
};

export default CompetitionForm;
