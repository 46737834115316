import React, { useState, useRef } from 'react';
import { graphql, Link as GatsbyLink, useStaticQuery } from 'gatsby';
import {
  Image as ChakraImage,
  SimpleGrid,
  Box,
  Heading,
  Text
} from '@chakra-ui/react';
import Button from '@components/atoms/Button';
import Image from '@components/atoms/Image';
import Wrapper from '@components/atoms/Wrapper';
import Section from '@components/atoms/Section';
import FilterBar from '@components/molecules/FilterBar';
import { ArrowRightIcon } from '@components/atoms/icons';
import scrollToPromise from '@helpers/scrollToPromise';
import noveltyBadge from '@assets/images/product-novelty-badge.png';

const ProductListing = ({
  products,
  brands,
  categories,
  title,
  ctaText,
  noResultsText
}) => {
  const { strapiSearchAndFilters: translations } = useStaticQuery(graphql`
    query {
      strapiSearchAndFilters {
        filterText
        brandFilterText
        categoryFilterText
      }
    }
  `);

  const filters = [
    {
      id: `filter-brand`,
      label: translations.brandFilterText || translations.filterText,
      name: `brand`,
      type: `checkbox`,
      items: brands.map((brand) => ({
        label: brand,
        value: brand
      }))
    },
    {
      id: `filter-category`,
      label: translations.categoryFilterText || translations.filterText,
      name: `category`,
      type: `checkbox`,
      items: categories.map((category) => ({
        label: category,
        value: category
      }))
    }
  ];

  const filterBarRef = useRef(null);
  const [filterValues, setFilterValues] = useState({ brand: [], category: [] });
  const filteredProducts = products
    // Filter to selected brand
    .filter((product) => {
      const brandIsSelected = filterValues.brand.length > 0;
      const categoryIsSelected = filterValues.category.length > 0;

      if (brandIsSelected === false && categoryIsSelected === false) {
        return true;
      }

      const productHasBrand =
        typeof product.brand !== `undefined` && product.brand !== null;
      const productHasCategory =
        typeof product.category !== `undefined` && product.category !== null;

      if (productHasBrand === true && productHasCategory === true) {
        const matchesBrand = filterValues.brand.indexOf(product.brand) !== -1;
        const matchesCategory =
          filterValues.category.indexOf(product.category) !== -1;

        if (brandIsSelected === true && categoryIsSelected === false) {
          return filterValues.brand.indexOf(product.brand) !== -1;
        }

        if (brandIsSelected === false && categoryIsSelected === true) {
          return filterValues.category.indexOf(product.category) !== -1;
        }

        return matchesBrand === true && matchesCategory === true;
      }

      if (
        productHasBrand === true &&
        productHasCategory === false &&
        brandIsSelected === true
      ) {
        return filterValues.brand.indexOf(product.brand) !== -1;
      }

      if (
        productHasBrand === false &&
        productHasCategory === true &&
        categoryIsSelected === true
      ) {
        return filterValues.category.indexOf(product.category) !== -1;
      }

      return false;
    })
    // Order novelty products first
    .sort((a, b) => {
      return b.novelty - a.novelty;
    });

  const submitFilter = (newFilterValues) => {
    setFilterValues(newFilterValues);
    scrollToPromise(filterBarRef.current);
  };

  return (
    <Section>
      <Wrapper>
        <FilterBar
          ref={filterBarRef}
          title={title}
          filters={filters}
          activeValues={filterValues}
          onSubmitFilter={submitFilter}
          searchEnabled={false}
        />
        {filteredProducts.length > 0 ? (
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing="14">
            {filteredProducts.map((product) => (
              <Box
                position="relative"
                sx={{
                  '.gatsby-image-wrapper': {
                    aspectRatio: `1/1`
                  }
                }}>
                {product.thumbnail && (
                  <Image
                    image={product.thumbnail}
                    objectFit="contain"
                    position="relative"
                  />
                )}
                {product.novelty && (
                  <ChakraImage
                    src={noveltyBadge}
                    position="absolute"
                    top="3"
                    right="3"
                    htmlWidth="100"
                  />
                )}
                {product.title && (
                  <Heading size="md" mt="4" mb="0">
                    {product.title}
                  </Heading>
                )}
                {product.brand && <Text mb="2">{product.brand}</Text>}
                {product.slug && ctaText && (
                  <Button
                    as={GatsbyLink}
                    to={`/products/${product.slug}`}
                    variant="secondary"
                    iconBorderColor="primary.froneri-blue"
                    rightIcon={<ArrowRightIcon />}>
                    {ctaText}
                  </Button>
                )}
              </Box>
            ))}
          </SimpleGrid>
        ) : (
          <Text>{noResultsText}</Text>
        )}
      </Wrapper>
    </Section>
  );
};

export default ProductListing;
