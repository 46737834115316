import React from 'react';
import { appendIfValid } from '@helpers/linkResolver';
import { localeRoot } from '@helpers/environment';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  AspectRatio,
  Box,
  useDisclosure
} from '@chakra-ui/react';

import Button from '@components/atoms/Button';
import { CloseIcon } from '@components/atoms/icons';

const ModalVideo = ({ videoSrc, videoTitle, renderControl }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Reminder: inbound video src should have a '/' at the start of it
  const videoSrcWithLocale = `${appendIfValid(localeRoot)}${videoSrc}`;

  return (
    <>
      {typeof renderControl === `function` && renderControl(onOpen)}
      <Modal
        size="4xl"
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        returnFocusOnClose={false}>
        <ModalOverlay bg="rgb(0, 28, 73, .7)" />
        <ModalContent
          position="relative"
          mx={{ base: 5, md: 14 }}
          borderRadius="none"
          boxShadow="none"
          bg="black">
          {videoSrcWithLocale && (
            <AspectRatio ratio={16 / 9}>
              <Box
                as="video"
                src={videoSrcWithLocale}
                title={videoTitle}
                controls
                playsInline
                autoPlay
                objectFit="cover"
                width="100%"
                height="100%">
                {videoTitle}
              </Box>
            </AspectRatio>
          )}
        </ModalContent>
        <Button
          color="primary.dark-blue"
          size="sm"
          icon={<CloseIcon color="white" boxSize="6" />}
          px="3"
          position="fixed"
          top="1"
          right="1"
          m="4"
          aria-label="Close"
          onClick={onClose}
          zIndex="modal"
        />
      </Modal>
    </>
  );
};

export default ModalVideo;
