import React from 'react';
import { Icon } from '@chakra-ui/react';

const LinkIcon = (props) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M19.6726 4.32782C17.902 2.55746 15.0309 2.55741 13.2611 4.3278L11.1606 6.4276C11.018 6.5701 11.1356 6.82538 11.3371 6.82538C11.5555 6.82538 11.7718 6.8372 11.9854 6.86056C12.7377 6.9428 13.5273 6.78504 14.0624 6.24997L14.6226 5.68981C15.1147 5.19708 15.7695 4.92613 16.4662 4.92613C17.1622 4.92613 17.8171 5.19708 18.3099 5.68981C18.802 6.18188 19.073 6.83534 19.073 7.53264C19.073 8.22859 18.802 8.88335 18.3099 9.37546L15.5053 12.1798C15.0125 12.6726 14.3577 12.9435 13.6617 12.9435C12.9643 12.9435 12.3109 12.6726 11.818 12.1798C11.8068 12.1686 11.7956 12.1573 11.7846 12.146C11.3884 11.7374 10.8191 11.337 10.4161 11.7389C9.97273 12.1826 9.85753 12.8815 10.274 13.3505C10.3322 13.416 10.3927 13.4803 10.4553 13.5431C12.2258 15.3136 15.0968 15.3136 16.8674 13.5431L19.6726 10.7374C21.4425 8.96772 21.4424 6.09755 19.6726 4.32782Z" />
    <path d="M13.6933 16.1737C13.6927 16.1735 13.692 16.1734 13.6914 16.1734C13.4693 16.1733 13.2488 16.1609 13.0304 16.1361C12.274 16.0504 11.4787 16.206 10.9403 16.7443L10.3764 17.3082C9.88425 17.801 9.23009 18.0719 8.53341 18.0719C7.8374 18.0719 7.18326 17.801 6.69046 17.3082C6.19769 16.8162 5.92672 16.1621 5.92672 15.4654C5.92672 14.7695 6.19769 14.1147 6.69046 13.6219L9.49501 10.8176C9.98778 10.3255 10.6413 10.0552 11.338 10.0552C12.0353 10.0552 12.6888 10.3262 13.1816 10.8176C13.1929 10.8288 13.204 10.8402 13.215 10.8516C13.6114 11.2623 14.1826 11.6634 14.5862 11.2598C15.0289 10.8164 15.1442 10.1184 14.7277 9.65014C14.6688 9.58394 14.6077 9.51905 14.5444 9.45556C12.7738 7.68517 9.9028 7.68517 8.13296 9.45556L5.3284 12.2613C3.5572 14.0317 3.5572 16.9011 5.3284 18.6722C7.09893 20.4426 9.9686 20.4426 11.7391 18.6722L13.8862 16.5253C14.0143 16.3972 13.9114 16.1728 13.7302 16.1739C13.7186 16.174 13.7069 16.1741 13.6952 16.1741C13.6945 16.1741 13.6939 16.1739 13.6933 16.1737Z" />
  </Icon>
);

export default LinkIcon;
