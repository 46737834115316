import { localeRoot } from '@helpers/environment';

// eslint-disable-next-line valid-jsdoc
/** If the supplied string exists, concat it to the start of the
 * supplied item string. Otherwise return the default value.
 */
export const appendIfValid = (str, item = ``, defaultValue = ``) => {
  return typeof str !== `undefined` && str !== null && str.length > 0
    ? `${str}${item}`
    : defaultValue;
};

const linkResolver = ({ parentSlug, slug, contentType, isHome = false }) => {
  const hasParentSlug =
    typeof parentSlug !== `undefined` && parentSlug !== null;

  const lowerCaseContentType = contentType.toLowerCase();

  if (lowerCaseContentType === `article`) {
    return hasParentSlug === true ? `/${parentSlug}/${slug}` : `/${slug}`;
  }

  if (lowerCaseContentType === `brand`) {
    return hasParentSlug === true ? `/${parentSlug}/${slug}` : `/${slug}`;
  }

  if (lowerCaseContentType === `job`) {
    return `/jobs/${slug}`;
  }

  // Note: this is pending removal
  if (lowerCaseContentType === `project`) {
    return `/projects/${slug}`;
  }
  // ...

  if (lowerCaseContentType === `landing-page`) {
    return isHome === true ? `/` : `/${slug}`;
  }

  return `${localeRoot}`;
};

export default linkResolver;
