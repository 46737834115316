import React from 'react';
import {
  Box,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer
} from '@chakra-ui/react';
import ApplyForm from '@components/organisms/ApplyForm';
import LexicalRichText from '@components/atoms/LexicalRichText';
import Wrapper from '@components/atoms/Wrapper';

const JobDescription = ({
  data: {
    jobTitle,
    jobCategory,
    jobLocation,
    lineManager,
    reference,
    salary,
    richText,
    closingDate
  }
}) => {
  return (
    <Box>
      <Wrapper maxWidth="64rem">
        <TableContainer marginBottom="3rem">
          <Table
            variant="unstyled"
            color="primary.dark-blue"
            textColor="primary.dark-blue">
            <Tbody>
              <TableRow th="Reference" td={reference} />
              {jobCategory !== null && (
                <TableRow th="Category" td={jobCategory.jobCategoryName} />
              )}
              <TableRow th="Role" td={jobTitle} />
              <TableRow th="Salary" td={salary} />
              {jobLocation !== null && (
                <TableRow th="Location" td={jobLocation.jobLocationName} />
              )}
              <TableRow th="Closing" td={closingDate} />
            </Tbody>
          </Table>
        </TableContainer>
      </Wrapper>
      <LexicalRichText data={{ richText }} />
      <ApplyForm
        jobReference={reference}
        jobTitle={jobTitle}
        lineManager={lineManager}
      />
    </Box>
  );
};

const TableRow = ({ th, td }) => {
  return (
    <Tr borderBottom="1px solid" borderColor="primary.froneri-blue">
      <Th
        paddingLeft="0"
        textTransform="normal"
        fontSize="medium"
        textColor="primary.dark-blue"
        width="20%">
        {th}
      </Th>
      <Td paddingRight="0">{td}</Td>
    </Tr>
  );
};

export default JobDescription;
