import React from 'react';
import { Icon } from '@chakra-ui/react';

const PauseIcon = (props) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M6 3.40121C6 3.17963 6.17963 3 6.4012 3H9.5988C9.82037 3 10 3.17963 10 3.4012V21.5988C10 21.8204 9.82037 22 9.5988 22H6.4012C6.17963 22 6 21.8204 6 21.5988V3.40121Z" />
    <path d="M14 3.40121C14 3.17963 14.1796 3 14.4012 3H17.5988C17.8204 3 18 3.17963 18 3.4012V21.5988C18 21.8204 17.8204 22 17.5988 22H14.4012C14.1796 22 14 21.8204 14 21.5988V3.40121Z" />
  </Icon>
);

export default PauseIcon;
