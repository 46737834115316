import React, { useCallback, useState, useEffect } from 'react';

import {
  Box,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage
} from '@chakra-ui/react';

const FieldComponent = ({
  fieldId,
  submitAttempted,
  defaultPlaceholder,
  fieldLabel,
  fieldValue,
  required,
  errorMessage,
  noFileSelectedMessage = `No file selected.`,
  onChange = (v) => {},
  onReady = () => {}
}) => {
  const [isError, setIsError] = useState(required);
  const [fileSelected, setFileSelected] = useState(null);
  const [accepted, setAccepted] = useState(null);

  const handleOnChange = useCallback(
    (fileRef) => {
      const fileIsNullOrUndefined =
        typeof fileRef === `undefined` || fileRef === null;

      const isEmpty = required === true && fileIsNullOrUndefined === true;

      const hasExceeded =
        fileIsNullOrUndefined === false && fileRef.size > 4194304; // 4mb

      const isInvalid = isEmpty || hasExceeded;

      setFileSelected(fileIsNullOrUndefined === false ? fileRef.name : null);
      setIsError(isInvalid);

      onChange({
        fieldId,
        value: fileRef,
        label: fieldLabel,
        isValid: isInvalid === false
      });
    },
    [onChange, fieldId, fieldLabel, required]
  );

  useEffect(() => {
    const acceptedTypes = Object.values(fieldValue)
      .map((item) => {
        return item.state === true ? item.accepted : ``;
      })
      .join();

    setAccepted(acceptedTypes);

    onReady({
      fieldId,
      required
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControl
      mb="3"
      isInvalid={submitAttempted && isError}
      isRequired={required}>
      <FormLabel
        id={`field-label-file-${fieldId}-${fieldLabel}`}
        htmlFor={fieldId}
        mb="2">
        {fieldLabel}
      </FormLabel>
      <Box pos="relative">
        <Input
          maxWidth="102px"
          type="file"
          id={fieldId}
          name={fieldLabel}
          placeholder={defaultPlaceholder}
          p="0"
          border="none"
          borderRadius="0"
          accept={accepted}
          onChange={(event) => handleOnChange(event.target.files[0])}
        />
        <Box pos="absolute" top="2px" left="28">
          {fileSelected !== null ? fileSelected : noFileSelectedMessage}
        </Box>
      </Box>
      {submitAttempted && isError && (
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export default FieldComponent;
