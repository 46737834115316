import React, { useEffect, useRef, useState, useCallback } from 'react';
import { Box } from '@chakra-ui/react';
import markerIcon from '@assets/images/map-marker.svg';

const mapStyles = [
  {
    featureType: `landscape.natural`,
    elementType: `geometry.fill`,
    stylers: [
      {
        visibility: `on`
      },
      {
        color: `#E2F6FA`
      }
    ]
  },
  {
    featureType: `poi`,
    elementType: `geometry.fill`,
    stylers: [
      {
        visibility: `on`
      },
      {
        color: `#E2F6FA`
      }
    ]
  },
  {
    featureType: `road`,
    elementType: `geometry`,
    stylers: [
      {
        lightness: 100
      },
      {
        visibility: `simplified`
      }
    ]
  },
  {
    featureType: `road`,
    elementType: `labels`,
    stylers: [
      {
        visibility: `off`
      }
    ]
  },
  {
    featureType: `transit.line`,
    elementType: `geometry`,
    stylers: [
      {
        visibility: `on`
      },
      {
        lightness: 700
      }
    ]
  },
  {
    featureType: `water`,
    elementType: `all`,
    stylers: [
      {
        color: `#00BAD5`
      }
    ]
  }
];

const SearchableMarkerMap = ({ markers, visibleMarkers, center, zoom }) => {
  const [map, setMap] = useState(null);
  const [mapMarkers, setMapMarkers] = useState(null);
  const [mapInfoWindow, setMapInfoWindow] = useState(null);
  const ref = useRef();

  // Opens the info window.
  const openInfoWindow = useCallback(
    (content, anchor) => {
      mapInfoWindow.setContent(content);
      mapInfoWindow.open({
        anchor,
        map
      });
    },
    [map, mapInfoWindow]
  );

  // Add marker click listeners to open info window.
  useEffect(() => {
    if (mapMarkers === null) {
      return;
    }

    mapMarkers.forEach((marker, index) => {
      const { infoWindowContent } = markers[index];
      marker.addListener(`click`, () => {
        return openInfoWindow(infoWindowContent, marker);
      });
    });
  }, [markers, mapMarkers, openInfoWindow]);

  // When visible markers change hide / show the relevant markers.
  useEffect(() => {
    if (mapMarkers === null) {
      return;
    }

    mapInfoWindow.close();

    mapMarkers.forEach((marker, index) => {
      marker.setMap(
        visibleMarkers === null || visibleMarkers.indexOf(index) !== -1
          ? map
          : null
      );
    });

    if (visibleMarkers !== null && visibleMarkers.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      visibleMarkers.forEach((index) => {
        bounds.extend(mapMarkers[index].getPosition());
      });
      map.fitBounds(bounds);
    } else {
      map.setCenter(center);
      map.setZoom(zoom);
    }
  }, [map, mapMarkers, mapInfoWindow, visibleMarkers, center, zoom]);

  // Initialise map elements.
  useEffect(() => {
    if (typeof window === `undefined`) {
      return;
    }

    setMap(
      new window.google.maps.Map(ref.current, {
        center,
        zoom,
        styles: mapStyles
      })
    );

    setMapMarkers(
      markers.map(({ title, position }) => {
        return new window.google.maps.Marker({
          title,
          position,
          icon: markerIcon
        });
      })
    );

    setMapInfoWindow(new window.google.maps.InfoWindow());
  }, [markers, center, zoom]);

  return <Box ref={ref} width="100%" height="600px" />;
};

export default SearchableMarkerMap;
