import React, { useCallback, useState, useEffect } from 'react';

import {
  Select,
  FormControl,
  FormLabel,
  FormErrorMessage
} from '@chakra-ui/react';

const SelectComponent = ({
  fieldId,
  submitAttempted,
  fieldLabel,
  fieldValue: options,
  required,
  errorMessage,
  overridePlaceholder,
  onReady = () => {},
  onChange = (value) => {}
}) => {
  const [isError, setIsError] = useState(!!overridePlaceholder && required);

  const handleOnChange = useCallback(
    (target) => {
      const { value, slug, index } = options[target.selectedIndex];
      const isInvalid = required === true && target.value.length === 0;

      setIsError(isInvalid);

      onChange({
        fieldId,
        value,
        slug,
        selectedIndex: index,
        label: fieldLabel,
        isValid: !isInvalid
      });
    },
    [onChange, fieldId, fieldLabel, required, options]
  );

  useEffect(() => {
    onReady({
      fieldId,
      required: !!overridePlaceholder && required
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControl
      mb="3"
      isInvalid={submitAttempted && isError}
      isRequired={required}>
      <FormLabel
        id={`field-label-select-${fieldId}-${fieldLabel}`}
        htmlFor={fieldId}
        mb="2">
        {fieldLabel}
      </FormLabel>
      <Select
        id={fieldId}
        name={fieldLabel}
        borderRadius="xs"
        borderColor="primary.dark-blue"
        onChange={(event) => handleOnChange(event.target)}
        onBlur={(event) => handleOnChange(event.target)}
        defaultValue={options && overridePlaceholder ? `` : options[0].value}>
        {options &&
          options.map(({ value, index }) => {
            return (
              <option key={`${fieldId}-${value}-${index}`} value={value}>
                {value}
              </option>
            );
          })}
        {options && overridePlaceholder && (
          <option disabled hidden value="">
            {overridePlaceholder}
          </option>
        )}
      </Select>
      {submitAttempted && isError && (
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
      )}
    </FormControl>
  );
};

export default SelectComponent;
