import React from 'react';
import { Heading, Box, Text } from '@chakra-ui/react';
import Section from '@components/atoms/Section';
import LexicalRichText from '@components/atoms/LexicalRichText';
import Wrapper from '@components/atoms/Wrapper';

const JobsEmbed = ({
  data: { title, richText, embedURL, embedHeight, legalText }
}) => {
  return (
    <Section>
      <Wrapper>
        {title && <Heading>{title}</Heading>}
        {richText && (
          <LexicalRichText
            data={{ richText }}
            props={{ maxWidth: null, px: null }}
          />
        )}
        {embedURL && (
          <Box
            as="iframe"
            src={embedURL}
            width="100%"
            minHeight={embedHeight !== null ? embedHeight : 400}
          />
        )}
        {legalText && (
          <Text mt="6" mb="0" fontWeight="bold">
            {legalText}
          </Text>
        )}
      </Wrapper>
    </Section>
  );
};
export default JobsEmbed;
