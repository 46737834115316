import React from 'react';
import { Icon } from '@chakra-ui/react';

const TwitterIcon = (props) => (
  <Icon viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M20.7509 8.78462C20.7509 8.60028 20.7509 8.41593 20.7509 8.23158C21.6267 7.62612 22.3879 6.87445 23 6.01063C22.1749 6.35746 21.3031 6.58513 20.4118 6.68657C21.3539 6.16535 22.0685 5.32186 22.4199 4.31639C21.5296 4.81496 20.5616 5.16498 19.555 5.35225C19.1218 4.91726 18.6035 4.57318 18.0313 4.34082C17.4591 4.10846 16.8449 3.99264 16.226 4.00036C15.05 3.9862 13.9159 4.4292 13.0703 5.23304C12.2246 6.03688 11.7357 7.13658 11.7099 8.29303C11.7123 8.62103 11.7512 8.94779 11.826 9.26744C10.0464 9.18896 8.30239 8.75096 6.70244 7.98073C5.10249 7.2105 3.68088 6.12452 2.52617 4.79042C2.12036 5.43746 1.9013 6.18095 1.89249 6.94115C1.89816 7.65708 2.08766 8.36 2.44341 8.98462C2.79916 9.60924 3.30962 10.1353 3.92738 10.514C3.21618 10.4922 2.51974 10.3089 1.89249 9.9785V10.0312C1.90311 11.0295 2.26434 11.9936 2.91497 12.7599C3.56561 13.5263 4.46562 14.0478 5.46247 14.236C5.07488 14.3353 4.67598 14.3855 4.27546 14.3853C3.97649 14.3864 3.67795 14.3629 3.38296 14.3151C3.68238 15.181 4.24932 15.9332 5.00457 16.4668C5.75983 17.0003 6.66567 17.2884 7.59554 17.291C5.9839 18.487 4.01853 19.1314 1.99959 19.1257C1.66547 19.1241 1.33172 19.1036 1 19.0642C3.07662 20.3315 5.47297 21.0006 7.91684 20.9955C9.57927 21.0399 11.234 20.7575 12.784 20.1647C14.334 19.572 15.7482 18.6808 16.9435 17.5435C18.1388 16.4063 19.0913 15.0457 19.7451 13.5417C20.3989 12.0376 20.7408 10.4203 20.7509 8.78462Z" />
  </Icon>
);

export default TwitterIcon;
